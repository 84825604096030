/* eslint-disable max-len */
/* eslint-disable camelcase */
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { config } from 'hub-redux-auth';

import API_ENDPOINTS from '@services/apiConfig';
import AuthManager from '@services/AuthManager';
import axios from 'axios';
import { ALLOWED_QUERY_PARAMS, AUTH_QUERY_PARAMS, PRISMIC_QUERY_PARAMS } from '@constants/queryParams';
import { getQueryParams, isASCII } from '@constants/utils';
import { setUTMCookies, getUTMCookies } from "../../../src/utils/cookieutil";
import { uiActions, hubActions, getProductAccountStatuses } from '.';

//  Action types
export const actionTypes = {
  fetch: 'productAccountDetails/v3/INITIATE',
  fetchFailed: 'productAccountDetails/v3/FETCH_FAILED',
  fetchFulfilled: 'productAccountDetails/v3/FETCH_FULFILLED',
  saveAllowedQueryParams: 'SAVE_ALLOWED_QUERY_PARAMS_V3',
  updateProductAccountDetails: 'productAccountDetails/v3/UPDATE',
  fetchAccountDetails: 'accountDetails/v3/INITATE',
  fetchKYCStatus: 'kycStatus/v3/INITATE',

  createProductAccount: 'createProductAccount/v3/INITITATE',
  createProductAccountSuccess: 'createProductAccount/v3/SUCCESS',
  createProductAccountFailed: 'createProductAccount/v3/FAILED'
};

const initialState = {
  fetching: false,
  fetched: false,
  response: null,
  errResponse: null,
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.fetch: {
      return {
        ...initialState,
        fetching: true,
      };
    }
    case actionTypes.fetchFulfilled: {
      return {
        ...initialState,
        fetched: true,
        response: action.payload,
      };
    }
    case actionTypes.fetchFailed: {
      return {
        ...initialState,
        fetched: true,
        errResponse: action.payload,
      };
    }
    case actionTypes.updateProductAccountDetails: {
      return {
        ...state,
        response: { ...state.response, ...action.payload },
      };
    }
    case actionTypes.createProductAccount:
      return { ...state, create: { ...state.create, processing: true } };
    case actionTypes.createProductAccountSuccess:
      return { ...state, create: {...state.create, processing: false, response: action?.payload } };
    case actionTypes.createProductAccountFailed:
      return { ...state, create: { ...state.create, processing: false, error: action?.payload } };
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  productAccountDetailsFetched: state => state.onboardingApp.productAccount.fetched,
  getProductAccountDetails: state => state.onboardingApp.productAccount.response,
  getHolderName: state =>
    state.onboardingApp.productAccount.response &&
    state.onboardingApp.productAccount.response.bank_detail &&
    state.onboardingApp.productAccount.response.bank_detail.holder_name,
  getNameOnPanCard: state =>
    (state.onboardingApp.productAccount.response ? state.onboardingApp.productAccount.response.pancard_name : null),
  getBankVerificationStatus: (state) => {
    const productAccountDetails = state.onboardingApp.productAccount.response || {
      bank_detail: {},
    };
    const {
      bank_detail: { bank_verification_status },
    } = productAccountDetails;
    return bank_verification_status;
  },
  getPennyStatus: (state) => {
    const productAccountDetails = state.onboardingApp.productAccount.response || {
      bank_detail: {},
    };
    const {
      bank_detail: { penny_deposit_status },
    } = productAccountDetails;
    return penny_deposit_status;
  },
  getBankDetails: (state) => {
    const productAccountDetails = state.onboardingApp.productAccount.response || {
      bank_detail: {},
    };
    const { bank_detail } = productAccountDetails;
    return bank_detail;
  },
  getProductAccountEmail: state => state.onboardingApp.productAccount.response && state.onboardingApp.productAccount.response.email,
  getProductAccountMobile: state =>
    state.onboardingApp.productAccount.response && state.onboardingApp.productAccount.response.registered_mobile,
  getProductAccountSettlementStatus: state => (
    state.onboardingApp.productAccount.response
    && state.onboardingApp.productAccount.response.settlement_status
  ),
  getProductAccountBusinessDetails: (state) => {
    const {
      business_category_id,
      business_category_name,
      business_sub_category_id,
      business_sub_category_name,
      gst_number,
      monthly_expected_volume,
      business_entity_id,
      business_name,
      pancard_name,
      business_pan_name_match,
      shop_number,
      area_code,
    } = state.onboardingApp.productAccount.response || {};
    return {
      business_entity_id,
      business_category_id,
      business_category_name,
      business_sub_category_id,
      business_sub_category_name,
      gst_number,
      monthly_expected_volume,
      business_name,
      pancard_name,
      business_pan_name_match,
      shop_number,
      area_code,
    };
  },
  getWebsiteDetails: (state) => {
    const productAccountDetails = (state.onboardingApp.productAccount.response &&
      state.onboardingApp.productAccount.response.website_detail) || {
      android_url: '',
      ios_url: '',
      website_url: '',
    };
    const websiteDetails = {
      android_url: productAccountDetails.android_url == null ? '' : productAccountDetails.android_url,
      ios_url: productAccountDetails.ios_url == null ? '' : productAccountDetails.ios_url,
      website_url: productAccountDetails.website_url == null ? '' : productAccountDetails.website_url,
      website_approval_status: productAccountDetails?.website_approval_status,
    };
    return websiteDetails;
  },
  getProductAccountIntegrationType: state => (state.onboardingApp.productAccount.response && state.onboardingApp.productAccount.response.integration_type) || null,
  getDocumentStatus: state => state.onboardingApp.productAccount.response && state.onboardingApp.productAccount.response.document_status,
  getAccountUuid: state =>
    state.onboardingApp.productAccount.response && state.onboardingApp.productAccount.response.account_uuid,
  getSigningAuthorityDetails: state => state.onboardingApp?.productAccount?.response?.signatory_contact_details?.[0],
  getRegistrationAddress: state => state.onboardingApp.productAccount?.response?.registration_address,
  getOperatingAddress: state => state.onboardingApp.productAccount?.response?.operating_address,
  getSavedKycAddress: state =>
    state.onboardingApp.productAccount.response && state.onboardingApp.productAccount.response.saved_kyc_address,
  getIsServiceAgreementEsigned: (state) => {
    const productAccountDetails = state.onboardingApp.productAccount.response;
    const productAccountStatuses = productAccountDetails?.product_account_statuses || [];
    const AgreementDetails = productAccountStatuses.find(v => v.status_type === 'Agreement') || {};
    return AgreementDetails?.status_value === 'Approved' || productAccountDetails?.is_service_agreement_esigned;
  },
  getDob: state => state?.onboardingApp?.productAccount?.response?.product_account_detail?.dob,
  getPepFlag: state => state?.onboardingApp?.productAccount?.response?.product_account_detail?.pep,
  getProductAccountGSTIn: state => state.onboardingApp.productAccount.response?.gst_number,
  getProductAccountPancardNumber: state => state.onboardingApp.productAccount.response?.pancard_number,
  getProductAccountName: state => state.onboardingApp.productAccount.response?.name,
  getBusinessEntityType: state => state.onboardingApp.productAccount.response?.business_entity?.name,
  getDisplayName: state => state.onboardingApp.productAccount?.response?.display_name,
  getUBO: (state) => state.onboardingApp.productAccount?.response?.ultimate_beneficiaries || [],
  isServiceAgreementGenerated: (state) => getProductAccountStatuses(state.onboardingApp.productAccount?.response || {})?.serviceAgreement?.generated,
  getMerchantEmail: (state) => state.onboardingApp.productAccount?.response?.email,
  getMerchantMobile: (state) => state.onboardingApp.productAccount?.response?.registered_mobile,
  getPanName: (state) => state?.onboardingApp?.productAccount?.response?.pancard_name,
  getSettlementEmbargoStatus: (state) => state.onboardingApp?.productAccount?.response?.new_settlement_embargo || null,
  isOnboardingCompleted: (state) => state.onboardingApp?.productAccount?.response?.onboarding_completed,
  isCreating: state => state.onboardingApp?.productAccount?.create?.processing || null,
  isProductAccountCreated: (state) => state.onboardingApp?.productAccount?.create?.response || null,
  getLendingInterest: (state) => state?.onboardingApp?.productAccount?.response?.lending_interest,
  getMcpTicket: (state) => state?.onboardingApp?.productAccount?.response?.product_account_detail?.mcp_ticket_id,
  getPrioritySettlementPlan: (state) => state?.onboardingApp?.productAccount?.response?.product_account_detail?.priority_settlement_plan,
  getRekycDocumentStatus: (state) => state.onboardingApp?.productAccount?.response?.re_kyc_document_status,
  getRekycFlag: (state) => state.onboardingApp?.productAccount?.response?.re_kyc_required,
  prioritySettlementFlag: (state) => state.onboardingApp?.productAccount?.response?.priority_settlement_eligible,
  businessMembers: (state) => state?.onboardingApp?.productAccount?.response?.business_members, 
  npoFlag: (state) => state.onboardingApp?.productAccount?.response?.product_account_detail?.npo,
  merchantType: (state) => state.onboardingApp?.productAccount?.response?.merchant_type,
  pepOnboardingStatusFlag: (state) => state.onboardingApp?.productAccount?.response?.product_account_detail?.pep_onboarding_status,
  isWorkingHours: (state) => state.onboardingApp?.productAccount?.response?.is_working_hours,
  getVkycInfo: (state) => state.onboardingApp?.productAccount?.response?.vkyc,
  vkycStatus: (state) =>  state.onboardingApp?.productAccount?.response?.vkyc_status,
  vkyWorkingHours: (state) => state.onboardingApp?.productAccount?.response?.working_hours_start_end,
  nextRekycDate: (state) => state.onboardingApp?.productAccount?.response?.product_account_detail?.next_rekyc_date,
  stopOnboardingFlag: (state) => state.onboardingApp?.productAccount?.response?.stop_onboarding,
  probToolsFlag: (state) => state.onboardingApp?.productAccount?.response?.prob_tools,
  isChildMerchant: state => state.onboardingApp.productAccount.response?.merchant_type == 'child_aggregator',
  getProductAccountType: state => state.onboardingApp.productAccount.response?.type,
  getKycDocumentsSubmittedStatus: (state) => {
    const productAccountStatuses = state.onboardingApp?.productAccount?.response?.product_account_statuses || [];
    const KycDocumentsSubmittedStatus = productAccountStatuses.find(v => v.status_type === 'KYC_DOCUMENTS') || {};
    return KycDocumentsSubmittedStatus?.status_value;
  },
  vkycConsent: state => state.onboardingApp.productAccount.response?.vkyc?.consent_given,
  gst_consent: (state) => {
    const consents = state.onboardingApp?.productAccount?.response?.consents || [];
    const gst_consent = consents?.find(v => v.name === 'gst_consent') || {};
    return gst_consent?.active;
  },
  copyMidParent: (state) => state.onboardingApp.productAccount?.response?.copy_mid_parent,
  isMerchantOffline: (state) => state.onboardingApp.productAccount?.response?.offline_merchant,
  askMerchantComment: (state) => state.onboardingApp.productAccount?.response?.sf_agent_comments?.agent_remarks,
  isVKYCAllowed: (state) => state.onboardingApp.productAccount?.response?.allow_vkyc,
  livelinessStatus: (state) => state.onboardingApp.productAccount?.response?.website_detail?.liveliness_status,
  allowSolePropToolsOnboarding: (state) => state.onboardingApp.productAccount?.response?.allow_sole_prop_tools_onboarding,
  bundleSubscriptionEligibleFlag: (state)=> state.onboardingApp.productAccount?.response?.bundle_subscription_eligible,
};

//  Actions
export const actions = {
  fetch: type => ({ type: actionTypes.fetch, payload: { type } }),
  fetchFulfilled: response => ({
    type: actionTypes.fetchFulfilled,
    payload: response,
  }),
  fetchFailed: errResponse => ({
    type: actionTypes.fetchFailed,
    payload: errResponse,
  }),
  saveAllowedQueryParams: location => ({
    type: actionTypes.saveAllowedQueryParams,
    payload: location,
  }),
  updateProductAccountDetails: productAccountDetails => ({
    type: actionTypes.updateProductAccountDetails,
    payload: productAccountDetails,
  }),
  fetchAccountDetails: data => ({
    type: actionTypes.fetchAccountDetails,
    payload: data,
  }),
  fetchKYCStatus: () => ({ type: actionTypes.fetchKYCStatus }),

  createProductAccount: (data) => ({
    type: actionTypes.createProductAccount,
    payload: data,
  }),
  createProductAccountSuccess: () => ({
    type: actionTypes.createProductAccountSuccess,
  }),
  createProductAccountFailed: (errResponse) => ({
    type: actionTypes.createProductAccountFailed,
    payload: errResponse,
  }),
};

//  Workers
function* fetchWorker(action) {
  const { type = '' } = action.payload || { type: '' };
  try {
    const {
      data: { product_account },
    } = yield call([axios, 'get'], API_ENDPOINTS.AUTH.PRODUCTACCOUNTS(AuthManager.getMerchantUUID())) || {
      data: { product_account: [] },
    };
    const productAccountDetails = product_account;
    yield put(actions.fetchFulfilled(productAccountDetails));
    yield put(hubActions.fetchHubToken());
    yield put(uiActions.loadUI(yield select(selectors.getProductAccountDetails), true));

    AuthManager.persistUserToLocalStorage({
      merchant_uuid: productAccountDetails ? productAccountDetails.uuid : null,
      product_account_uuid: productAccountDetails ? productAccountDetails.uuid : null,
      mid: productAccountDetails ? productAccountDetails.mid : null,
      name: productAccountDetails ? productAccountDetails.name : null,
      product: productAccountDetails ? productAccountDetails.product : null,
      type: productAccountDetails ? productAccountDetails.type : null,
      dashboard_preference: productAccountDetails ? productAccountDetails.dashboard_preference : null,
      uuid: productAccountDetails ? productAccountDetails.uuid : null,
      email: productAccountDetails ? productAccountDetails.email : null,
      cs_plan: productAccountDetails ? productAccountDetails.cs_plan : null,
      is_cs_eligible: productAccountDetails ? productAccountDetails.is_cs_eligible : null,
      merchant_type: productAccountDetails ? productAccountDetails.merchant_type : null,
    }, type);
  } catch (err) {
    const errResponse = yield err.response;
    console.log(errResponse, err);
    yield put(actions.fetchFailed(errResponse));
  }
}

/**
 * Set current query params or saved utm params
 */
const setAllowedQueryParamsInConfig = (queryParams) => {
  try {
    const savedUTMParams = getUTMCookies()
    config.set('ALLOWED_QUERY_PARAMS', { ...(savedUTMParams || {}), ...queryParams });
  } catch {}
}

function* saveAllowedQueryParamsWorker(action) {
  const location = action.payload;
  const queryParams = {};
  ALLOWED_QUERY_PARAMS.forEach((key) => {
    const value = getQueryParams(key, location);
    if (value && isASCII(decodeURI(value))) {
      queryParams[key] = decodeURI(value);
      key == 'reseller_id' && window.localStorage.setItem('reseller_id', value);
      key == 'redirect_uri' && window.localStorage.setItem('redirect_uri', value);
    }
  });
  setAllowedQueryParamsInConfig(queryParams)
  // set utm params cookies
  setUTMCookies(queryParams);
  const campaignContext = getQueryParams(PRISMIC_QUERY_PARAMS.CAMPAIGN_CONTEXT, location);
  if (campaignContext && campaignContext?.length < 255 && isASCII(decodeURI(campaignContext))) {
    config.set(PRISMIC_QUERY_PARAMS.CAMPAIGN_CONTEXT, decodeURI(campaignContext));
  }
  const authToken = getQueryParams(AUTH_QUERY_PARAMS.authToken, location);
  const product_account_uuid = getQueryParams(AUTH_QUERY_PARAMS.productAccountUuid, location) || getQueryParams(AUTH_QUERY_PARAMS.merchantUuid, location);
  const appSource = getQueryParams(AUTH_QUERY_PARAMS.appSource, location);
  AuthManager.persistAppSource(appSource && isASCII(decodeURI(appSource)) ? appSource : 'NA');
  if (authToken && product_account_uuid) {
    const tokenData = {
      access_token: authToken,
      expires_in: 86399,
    };
    AuthManager.removeLocalStorageData();
    AuthManager.persistFullToken(tokenData);
    AuthManager.persistUserToLocalStorage({ product_account_uuid });
  }
}

function* fetchAccountDetailsWorker({ payload }) {
  const accountUuid = yield select(selectors.getAccountUuid);
  try {
    let response = null;
    if (accountUuid && payload.type != 'child') {
      response = yield call([axios, 'get'], API_ENDPOINTS.SUBMIT_SIGNING_AUTHORITY(accountUuid)) || {};
    }
    if (response && response.data) yield put(actions.updateProductAccountDetails(response.data));
    // yield put(actions.fetchKYCStatus());
    yield put(uiActions.loadUI(yield select(selectors.getProductAccountDetails), true));
  } catch {
    yield put(uiActions.loadUI(yield select(selectors.getProductAccountDetails), true));
  }
}

function* fetchKYCStatusWorker() {
  try {
    const response = yield call([axios, 'get'], API_ENDPOINTS.KYC.STATUS(AuthManager.getMerchantUUID())) || {};
    if (response && response.data) yield put(actions.updateProductAccountDetails(response.data));
    yield put(uiActions.loadUI(yield select(selectors.getProductAccountDetails), true));
  } catch {
    yield put(uiActions.loadUI(yield select(selectors.getProductAccountDetails), true));
  }
}

function* createProductAccountWorker(action) {
  try {
    const { payload } = action;
    const mobile =   yield select(selectors.getMerchantMobile);
    const email = yield select(selectors.getMerchantEmail);
    const response = yield call(
      [axios, 'post'],
      API_ENDPOINTS.CREATE_PRODUCT_ACCOUNT,
      {
        product_account: {
          mobile,
          email,
          product: payload,
        }
      },
    );
    yield put({ type: actionTypes.createProductAccountSuccess, payload: response?.data });
  } catch {
    yield put({ type: actionTypes.createProductAccountFailed });
  }
}

//  Sagas
export function* saga() {
  yield takeLatest(actionTypes.fetch, fetchWorker);
  yield takeLatest(actionTypes.saveAllowedQueryParams, saveAllowedQueryParamsWorker);
  yield takeLatest(actionTypes.fetchAccountDetails, fetchAccountDetailsWorker);
  yield takeLatest(actionTypes.fetchKYCStatus, fetchKYCStatusWorker);
  yield takeLatest(actionTypes.createProductAccount, createProductAccountWorker);
}
